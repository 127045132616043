<template>
    <div class="h-100">
        <el-container class="h-100">
            <el-aside width="320px" class="bg-white border-right">
                <el-container class="h-100">
                    <el-header height="auto" class="p-3 border-bottom border-light">通知分类</el-header>
                    <el-main class="p-0 narrow-scrollbar">
                        <el-menu :default-active="articleCategoryId" class="border-0" @select="selectArticleCategory">
                            <el-menu-item v-for="articleCategory in articleCategories" :key="articleCategory.id"
                                :index="articleCategory.id">
                                <font-awesome-icon icon="stream" fixed-width style="font-size: 16px;" />
                                <span class="ml-3">{{articleCategory.name}}</span>
                            </el-menu-item>
                        </el-menu>
                    </el-main>
                </el-container>
            </el-aside>
            <el-main>
                <div class="text-center">
                    <el-radio-group v-model="isOnline" @change="loadArticles(true)">
                        <el-radio-button :label="null">全部</el-radio-button>
                        <el-radio-button :label="true">已上线</el-radio-button>
                        <el-radio-button :label="false">已下线</el-radio-button>
                    </el-radio-group>
                </div>

                <el-card shadow="never" class="mt-3">
                    <div class="row no-gutters">
                        <div class="col">
                            <el-button type="primary" @click="showArticleDialog(null)">添加</el-button>
                        </div>
                    </div>
                    <el-table :data="articles.data" class="mt-2">
                        <el-table-column prop="title" label="标题" sortable>
                            <template #default="scope">
                                <router-link :to="'/article/view?id='+scope.row.id" target="_blank">
                                    {{scope.row.title}}
                                </router-link>
                            </template>
                        </el-table-column>
                        <el-table-column prop="publishTime" label="发表时间" sortable :formatter="$moment.sdFormatter"
                            width="120" />
                        <el-table-column label="操作" fixed="right" width="140">
                            <template #default="scope">
                                <el-button v-if="scope.row.isOnline" type="text"
                                    @click="updateArticleIsOnline(scope.row.id,false)">
                                    下线
                                </el-button>
                                <el-button v-else type="text" @click="updateArticleIsOnline(scope.row.id,true)">
                                    上线
                                </el-button>
                                <el-button type="text" @click="showArticleDialog(scope.row)">修改</el-button>
                                <el-button type="text" class="text-danger" @click="deleteArticle(scope.row.id)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination layout="total,->,prev,pager,next" :total="articles.totalRecords"
                        :current-page="articlesPagination.currentPage" :page-size="articlesPagination.pageSize"
                        class="mt-3" @current-change="articlesPaginationCurrentChange" />
                </el-card>
            </el-main>
        </el-container>

        <el-dialog :visible.sync="articleDialogVisible" title="通知" :close-on-click-modal="false" width="960px" top="0">
            <el-form>
                <el-row :gutter="8">
                    <el-col :span="6">
                        <el-form-item>
                            <el-select v-model="article.articleCategoryId" placeholder="分类">
                                <el-option v-for="articleCategory in articleCategories" :key="articleCategory.id"
                                    :value="articleCategory.id" :label="articleCategory.name" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="18">
                        <el-form-item>
                            <el-input v-model="article.title" placeholder="标题" />
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-form-item>
                    <tinymce v-model="article.content" placeholder="内容" :min-height="480" :max-height="480"
                        class="article-content" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="primary" @click="saveArticle">确定</el-button>
                <el-button @click="articleDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                articleCategories: [],
                articleCategoryId: null,
                isOnline: null,
                articles: {},
                articlesPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                article: {},
                articleDialogVisible: false,
            };
        },
        methods: {
            async loadArticleCategories() {
                let response = await this.$axios.get('/api/ArticleCategory/GetArticleCategories');
                this.articleCategories = response.data;
                if (response.data.length != 0) {
                    this.selectArticleCategory(response.data[0].id);
                }
            },
            selectArticleCategory(index) {
                this.articleCategoryId = index;
                this.loadArticles(true);
            },
            async loadArticles(resetPage = false) {
                if (resetPage) {
                    this.articlesPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Article/GetArticles', {
                    params: {
                        articleCategoryId: this.articleCategoryId,
                        isOnline: this.isOnline,
                        pageIndex: this.articlesPagination.currentPage - 1,
                        pageSize: this.articlesPagination.pageSize,
                    }
                });
                this.articles = response.data;
            },
            articlesPaginationCurrentChange(page) {
                this.articlesPagination.currentPage = page;
                this.loadArticles();
            },
            showArticleDialog(article) {
                if (article) {
                    this.article = JSON.parse(JSON.stringify(article));
                }
                else {
                    this.article = { articleCategoryId: this.articleCategoryId };
                }
                this.articleDialogVisible = true;
            },
            async saveArticle() {
                if (this.article.id == undefined) {
                    await this.$axios.post('/api/Article/AddArticle', {
                        articleCategoryId: this.article.articleCategoryId,
                        title: this.article.title,
                        content: this.article.content,
                    });
                    this.articleDialogVisible = false;
                    this.loadArticles();
                }
                else {
                    await this.$axios.post('/api/Article/UpdateArticle', {
                        id: this.article.id,
                        articleCategoryId: this.article.articleCategoryId,
                        title: this.article.title,
                        content: this.article.content,
                    });
                    this.articleDialogVisible = false;
                    this.loadArticles();
                }
            },
            async updateArticleIsOnline(id, isOnline) {
                await this.$axios.post('/api/Article/UpdateArticleIsOnline', {
                    id: id,
                    isOnline: isOnline,
                });
                this.loadArticles();
            },
            async deleteArticle(id) {
                if (!confirm('确定要删除吗？')) {
                    return;
                }

                await this.$axios.post('/api/Article/DeleteArticle', { id: id });
                this.loadArticles();
            },
        },
        created() {
            this.loadArticleCategories();
        },
    };
</script>

<style>
    .article-content .tox-tinymce {
        height: calc(100vh - 280px) !important;
    }
</style>